import { IDDChampion, IDDSpell } from 'types/api/ddragon';
import { IDatabaseGame } from 'types/database/league';
import { IResponseSummoner } from 'types/response';
import { action } from 'typesafe-actions';
import { ActionTypes } from './constants';

export const setLoading = (isLoading: boolean) =>
  action(ActionTypes.SET_LOADING, { isLoading });

export const setGames = (games: IDatabaseGame[]) =>
  action(ActionTypes.SET_GAMES, { games });

export const setSummoners = (summoners: IResponseSummoner[]) =>
  action(ActionTypes.SET_SUMMONERS, { summoners });

export const setDDRagonData = (
  version: string,
  champions: IDDChampion[],
  spells: IDDSpell[],
) => action(ActionTypes.SET_DDRAGON_DATA, { version, champions, spells });
