import React from 'react';
import { useSelector } from 'react-redux';

import { createStructuredSelector } from 'reselect';
import { selectDDragonSpells } from 'actions/selectors';

import { BanCard } from './Ban';
import { ParticipantCard } from './Participant';

import { IDatabaseGame } from 'types/database/league';

import queues from '../../utils/queues.json';

interface IProps {
  selectedGame: IDatabaseGame;
}

const stateSelector = createStructuredSelector({
  spells: selectDDragonSpells(),
});

export const Game: React.FC<IProps> = ({ selectedGame }) => {
  const { spells } = useSelector(stateSelector);

  const queue = queues.find(q => q.queueId === selectedGame.gameQueueConfigId);

  return (
    <div className="mx-auto my-auto">
      <div className="grid sm:grid-cols-1 md:grid-cols-10 gap-4 my-5 max-w-screen-xl w-full">
        {selectedGame.participants
          .filter(p => p.teamId === 100)
          .map((participant, index) => {
            return (
              <ParticipantCard
                participant={participant}
                summonerSpells={spells}
                key={index}
                server={selectedGame.server}
              />
            );
          })}
      </div>

      <div className="grid grid-cols-3 gap-5 my-5">
        <div className="grid grid-cols-5 gap-1 self-center justify-self-start">
          {selectedGame.bannedChampions
            .filter(b => b.teamId === 100)
            .sort((a, b) => a.pickTurn - b.pickTurn)
            .map((ban, index) => {
              return <BanCard ban={ban} key={index} />;
            })}
        </div>
        <div className="self-center text-xl font-semibold justify-self-center">
          {queue?.description?.replace('games', '')}
        </div>
        <div className="grid grid-cols-5 gap-1 self-center justify-self-end">
          {selectedGame.bannedChampions
            .filter(b => b.teamId === 200)
            .sort((a, b) => a.pickTurn - b.pickTurn)
            .map((ban, index) => {
              return <BanCard ban={ban} key={index} />;
            })}
        </div>
      </div>

      <div className="grid sm:grid-cols-1 md:grid-cols-10 gap-4 my-5 max-w-screen-xl w-full">
        {selectedGame.participants
          .filter(p => p.teamId === 200)
          .map((participant, index) => {
            return (
              <ParticipantCard
                participant={participant}
                summonerSpells={spells}
                key={index}
                server={selectedGame.server}
              />
            );
          })}
      </div>
    </div>
  );
};
