import { Transition } from '@tailwindui/react';
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';

import { IHistorySearch, IServer } from 'types/web';

import { getSummonersSearchHistory } from 'utils/storage';

interface IProps {
  username: string;
  setUsername: Dispatch<SetStateAction<string>>;
  selectedServer: IServer;
}

export const UsernamesDropdown: React.FC<IProps> = ({
  username,
  setUsername,
  selectedServer,
}) => {
  const [summonersHistory, setSummonersHistory] = useState<
    IHistorySearch[] | undefined
  >();
  const [filteredSummonersHistory, setFilteredSummonersHistory] = useState<
    IHistorySearch[] | undefined
  >();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const container = useRef(null);
  const dropdown = useRef(null);

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (
        !dropdownOpen ||
        //@ts-ignore
        dropdown.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  useEffect(() => {
    const summonersHistory = getSummonersSearchHistory();
    setSummonersHistory(summonersHistory);
  }, []);

  useEffect(() => {
    if (!filteredSummonersHistory || filteredSummonersHistory.length === 0)
      setDropdownOpen(false);
    else if (
      filteredSummonersHistory?.length === 1 &&
      filteredSummonersHistory[0].username === username
    )
      setDropdownOpen(false);
    else setDropdownOpen(true);
  }, [filteredSummonersHistory]);

  useEffect(() => {
    if (username.length === 0)
      setFilteredSummonersHistory(
        summonersHistory?.filter(s => s.server === selectedServer.name),
      );
    else
      setFilteredSummonersHistory(
        summonersHistory?.filter(
          s =>
            s.server === selectedServer.name &&
            s.username.toLowerCase().includes(username.toLowerCase()),
        ),
      );
  }, [username, selectedServer]);

  const handleSelectUsername = (username: string) => {
    setDropdownOpen(false);
    setUsername(username);
  };

  return (
    <div className="relative w-4/5">
      <input
        ref={container}
        className="form-input py-3 font-semibold text-center focus:border-slate-300 w-full"
        aria-haspopup="true"
        onClick={() =>
          filteredSummonersHistory?.length === 0
            ? setDropdownOpen(false)
            : setDropdownOpen(true)
        }
        aria-expanded={dropdownOpen}
        value={username}
        onChange={e => setUsername(e.target.value)}
      />

      <Transition
        className="origin-top-right z-10 absolute top-full w-full bg-white border border-slate-200 py-1.5 rounded shadow-lg overflow-hidden mt-1"
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterFrom="opacity-0 -translate-y-2"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          <ul>
            {filteredSummonersHistory ? (
              filteredSummonersHistory.map((summoner, key) => {
                return (
                  <li
                    className="cursor-pointer hover:bg-indigo-300 text-center"
                    key={key}
                    onClick={() => handleSelectUsername(summoner.username)}
                  >
                    {summoner.username}
                  </li>
                );
              })
            ) : (
              <></>
            )}
          </ul>
        </div>
      </Transition>
    </div>
  );
};
