import React, { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router';

import { SummonerSearch } from 'components/Search/Summoner';

import { servers } from 'utils/constants';
import { setSummonersSearchHistory } from 'utils/storage';

import { IServer } from 'types/web';

interface IProps {
  sidebarOpen: boolean;
  setSidebarOpen: Dispatch<SetStateAction<boolean>>;
  searchType?: 'summoner' | 'live';
}

export const Header: React.FC<IProps> = ({
  sidebarOpen,
  setSidebarOpen,
  searchType,
}) => {
  const [selectedUsername, setSelectedUsername] = useState<string>('');
  const [selectedServer, setSelectedServer] = useState<IServer>(servers[0]);

  const navigate = useNavigate();

  const handleSubmit = e => {
    e.preventDefault();
    if (!searchType) return;

    if (searchType === 'summoner') {
      if (selectedServer && selectedUsername.length)
        setSummonersSearchHistory(selectedServer, selectedUsername);
      if (selectedUsername.length)
        navigate(
          `/summoner/${selectedServer.name.toLowerCase()}/${selectedUsername}`,
        );
    }
  };

  return (
    <header className="sticky top-0 bg-white border-b border-slate-200 z-30">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16 -mb-px">
          <div className="flex">
            <button
              className="text-slate-500 hover:text-slate-600 lg:hidden"
              aria-controls="sidebar"
              aria-expanded={sidebarOpen}
              onClick={() => setSidebarOpen(!sidebarOpen)}
            >
              <span className="sr-only">Open sidebar</span>
              <svg
                className="w-6 h-6 fill-current"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="4" y="5" width="16" height="2" />
                <rect x="4" y="11" width="16" height="2" />
                <rect x="4" y="17" width="16" height="2" />
              </svg>
            </button>
          </div>

          <form
            className="flex flex-row space-x-2 sm:w-1/3"
            onSubmit={handleSubmit}
          >
            <SummonerSearch
              selectedServer={selectedServer}
              setSelectedServer={setSelectedServer}
              username={selectedUsername}
              setUsername={setSelectedUsername}
              servers={servers}
            />
            <button
              type="submit"
              className="btn-lg bg-white border border-slate-200 hover:border-slate-300 hidden sm:block"
            >
              Search
            </button>
          </form>
        </div>
      </div>
    </header>
  );
};
