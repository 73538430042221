import React, { Dispatch, SetStateAction, useState } from 'react';
import { useSelector } from 'react-redux';

import { createStructuredSelector } from 'reselect';
import { selectDDragonVersion } from 'actions/selectors';

import { IDatabaseGame } from 'types/database/league';

interface IProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  games: IDatabaseGame[];
  selectedGame: IDatabaseGame | undefined;
  setSelectedGame: Dispatch<SetStateAction<IDatabaseGame | undefined>>;
}

const stateSelector = createStructuredSelector({
  version: selectDDragonVersion(),
});

export const LiveSidebar: React.FC<IProps> = ({
  open,
  setOpen,
  games,
  selectedGame,
  setSelectedGame,
}) => {
  const { version } = useSelector(stateSelector);

  const [search, setSearch] = useState<string>('');

  const setGame = (account: IDatabaseGame) => {
    setSelectedGame(account);
    setOpen(false);
  };

  return (
    <div
      id="profile-sidebar"
      className={`absolute z-20 top-0 bottom-0 w-full md:w-auto md:static md:top-auto md:bottom-auto -mr-px md:translate-x-0 transition-transform duration-200 ease-in-out ${
        open ? 'translate-x-0' : '-translate-x-full'
      }`}
    >
      <div className="sticky top-16 bg-white overflow-x-hidden overflow-y-auto no-scrollbar shrink-0 border-r border-slate-200 md:w-72 xl:w-80 h-[calc(100vh-64px)]">
        <div>
          <div className="sticky top-0 z-10">
            <div className="flex items-center bg-white border-b border-slate-200 px-5 h-16">
              <div className="w-full flex items-center justify-between"></div>
            </div>
          </div>
          <div className="px-5 py-4">
            <form className="relative">
              <label htmlFor="profile-search" className="sr-only">
                Search
              </label>
              <input
                id="profile-search"
                className="form-input w-full focus:border-slate-300"
                type="search"
                placeholder="Поиск..."
                value={search}
                onChange={e => setSearch(e.target.value.toLowerCase())}
              />
            </form>
            <div className="mt-4">
              <div className="text-xs font-semibold text-slate-400 uppercase mb-3">
                Найдено Активных Игр: {games.length}
              </div>
              <ul className="mb-6 mt-2">
                {games.map(game => {
                  const gameSummoners = game.participants.filter(
                    s => s.tracked,
                  );

                  return (
                    <li className="-mx-2">
                      <button
                        className={`w-full p-2 rounded" ${
                          selectedGame?.gameId === game.gameId &&
                          'bg-indigo-100'
                        }`}
                        onClick={() => setGame(game)}
                      >
                        {gameSummoners.length === 1 ? (
                          <div className="flex items-center">
                            <div className="relative mr-2">
                              <img
                                className="w-8 h-8 rounded-md"
                                src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/profileicon/${gameSummoners[0].profileIconId}.png`}
                                width="32"
                                height="32"
                                alt="User"
                              />
                            </div>
                            <div className="truncate">
                              <span className="text-sm font-medium text-slate-800">
                                {gameSummoners[0].summonerName}
                              </span>
                            </div>
                            <div className="relative mr-0 ml-auto">
                              <img
                                className="w-8 h-8 rounded-full"
                                src={require(`../../images/ranked/emblems/${
                                  gameSummoners[0].ranked.solo?.tier.toLowerCase() ||
                                  'unranked'
                                }.png`)}
                                width="32"
                                height="32"
                                alt="User"
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="flex flex-col space-y-3">
                            {gameSummoners.map((summoner, index) => {
                              return (
                                <div className="flex items-center" key={index}>
                                  <div className="relative mr-2">
                                    <img
                                      className="w-8 h-8 rounded-full"
                                      src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/profileicon/${summoner.profileIconId}.png`}
                                      width="32"
                                      height="32"
                                      alt="User"
                                    />
                                  </div>
                                  <div className="truncate">
                                    <span className="text-sm font-medium text-slate-800">
                                      {summoner.summonerName}
                                    </span>
                                  </div>
                                  <div className="relative mr-0 ml-auto">
                                    <img
                                      className="w-8 h-8 rounded-full"
                                      src={require(`../../images/ranked/emblems/${
                                        summoner.ranked.solo?.tier.toLowerCase() ||
                                        'unranked'
                                      }.png`)}
                                      width="32"
                                      height="32"
                                      alt="User"
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
