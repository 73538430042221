import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import moment from 'moment';

import API from 'api';

import { Game } from './Game';
import { GameEndModal } from 'components/Modal/GameEndModal';

import { IDatabaseGame } from 'types/database/league';

interface IProps {
  selectedGameSidebarOpen: boolean;
  setSelectedGameSidebarOpen: Dispatch<SetStateAction<boolean>>;
  selectedGame: IDatabaseGame | undefined;
}

const api = new API();

export const LiveBody: React.FC<IProps> = ({
  selectedGameSidebarOpen,
  setSelectedGameSidebarOpen,
  selectedGame,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (!selectedGame) return;
    if (selectedGame.isOver && !modalOpen) setModalOpen(true);

    // setDiff(moment.duration(moment.now() - selectedGame.gameStartTime));
  }, [selectedGame]);

  return !selectedGame ? (
    <></>
  ) : (
    <div
      className={`grow flex flex-col justify-between md:translate-x-0 transition-transform duration-300 ease-in-out ${
        selectedGameSidebarOpen ? 'translate-x-1/3' : 'translate-x-0'
      }`}
    >
      <button
        className="md:hidden absolute top-4 left-4 sm:left-6 text-white opacity-80 hover:opacity-100"
        onClick={() => setSelectedGameSidebarOpen(!selectedGameSidebarOpen)}
        aria-controls="profile-sidebar"
        aria-expanded={selectedGameSidebarOpen}
      >
        <span className="sr-only">Close sidebar</span>
        <svg
          className="w-6 h-6 fill-current"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
        </svg>
      </button>

      <Game selectedGame={selectedGame} />
      <GameEndModal
        id={selectedGame.gameId}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      />
    </div>
  );
};
