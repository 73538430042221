import superagent from 'superagent';
import { IDDChampion, IDDSpell } from 'types/api/ddragon';

export default class DDragon {
  private version = '12.17.1';
  private readonly locale = 'en_US';
  private readonly base_data = `https://ddragon.leagueoflegends.com/cdn/${this.version}/data/${this.locale}`;

  public async getVersion(): Promise<string> {
    try {
      const response = await superagent.get(
        `https://ddragon.leagueoflegends.com/api/versions.json`,
      );

      if (response.status !== 200) return this.version;
      this.version = String(response.body.at(0));

      return this.version;
    } catch (error) {
      return this.version;
    }
  }

  public async getChampions(): Promise<IDDChampion[]> {
    try {
      const champions: IDDChampion[] = [];
      const response = await superagent.get(
        `https://ddragon.leagueoflegends.com/cdn/12.18.1/data/en_US/champion.json`,
      );

      if (response.status !== 200) return [];

      for (const champion of Object.entries(response.body.data)) {
        const championData = champion[1];
        champions.push(championData as IDDChampion);
      }

      return champions;
    } catch (error) {
      return [];
    }
  }

  public async getSummonerSpells(): Promise<IDDSpell[]> {
    try {
      const spells: IDDSpell[] = [];
      const response = await superagent.get(`${this.base_data}/summoner.json`);

      if (response.status !== 200) return [];

      for (const champion of Object.entries(response.body.data)) {
        const championData = champion[1];
        spells.push(championData as IDDSpell);
      }

      return spells;
    } catch (error) {
      return [];
    }
  }

  public async getChallenges() {
    try {
      const response = await superagent.get(
        `${this.base_data}/challenges.json`,
      );

      return response.body;
    } catch (error) {
      return undefined;
    }
  }
}
