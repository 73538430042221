import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createStructuredSelector } from 'reselect';
import { setLoading } from 'actions/actions';
import { selectDDragonVersion } from 'actions/selectors';

import API from 'api';

import { IDDChallenges } from 'types/api/ddragon';
import { IResponseSummoner } from 'types/response';
import { ERank } from 'types/api/ranked';

const api = new API();

const stateSelector = createStructuredSelector({
  version: selectDDragonVersion(),
});

interface IProps {
  summoner: IResponseSummoner;
  challengesData: IDDChallenges[];
}

export const SummonerData: React.FC<IProps> = ({
  summoner,
  challengesData,
}) => {
  const { version } = useSelector(stateSelector);

  const dispatch = useDispatch();

  const isOneRank = (rank: ERank) => {
    return (
      rank === ERank.CHALLENGER ||
      rank === ERank.GRANDMASTER ||
      rank === ERank.MASTER
    );
  };

  const getChallengeData = (id: number): IDDChallenges | undefined => {
    return challengesData.find(c => c.id === id);
  };

  const handleAddToTracking = async e => {
    e.preventDefault();
    dispatch(setLoading(true));

    const startTracking = await api.league.trackSummoner(
      summoner.data.name,
      summoner.server,
    );
    if (startTracking) summoner.trackGames = true;
    dispatch(setLoading(false));
  };

  return (
    <div className="w-full md:w-60 mb-8 md:mb-0">
      <div className="lg:sticky top-0 lg:h-[calc(100vh-64px)] lg:overflow-x-hidden lg:overflow-y-auto no-scrollbar">
        <div className="md:py-8">
          <div className="space-y-4">
            <div className="bg-slate-50 p-4 rounded border border-slate-200">
              <div className="flex items-center mb-4">
                <div className="w-10 h-10 shrink-0 mr-3">
                  <img
                    className="rounded-md"
                    src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/profileicon/${summoner.data.profileIconId}.png`}
                    width="40"
                    height="40"
                    alt="User 04"
                  />
                </div>
                <div>
                  <div className="font-semibold text-slate-800">
                    {summoner.data.name}
                  </div>
                  <div className="text-sm text-slate-500 italic">
                    {summoner.data.summonerLevel} level
                  </div>
                </div>
              </div>
              <div className="text-sm space-y-2">IN PROGRESS</div>
              <div className="mt-4">
                <button
                  className={`btn w-full text-white ${
                    summoner.trackGames
                      ? 'cursor-not-allowed bg-emerald-600'
                      : 'cursor-pointer bg-indigo-500 hover:bg-indigo-600'
                  }`}
                  onClick={e =>
                    summoner.trackGames ? {} : handleAddToTracking(e)
                  }
                >
                  {summoner.trackGames ? 'Already Tracked' : 'Add To Tracking'}
                </button>
              </div>
            </div>

            <div className="bg-slate-50 p-4 rounded border border-slate-200">
              <div className="text-xs font-semibold text-slate-400 uppercase mb-4">
                Ranked SOLO/DUO
              </div>
              <div className="flex items-center mb-4">
                <div className="w-10 h-10 shrink-0 mr-3">
                  <img
                    className="rounded-full"
                    src={require(`../../images/ranked/emblems/${
                      summoner.ranked.solo?.tier.toLowerCase() || 'unranked'
                    }.png`)}
                    width="40"
                    height="40"
                    alt="Ranked Solo"
                  />
                </div>
                {summoner.ranked.solo && (
                  <div>
                    <div className="font-semibold text-slate-800">
                      {summoner.ranked.solo
                        ? `${summoner.ranked.solo.tier} ${
                            !isOneRank(summoner.ranked.solo.tier)
                              ? summoner.ranked.solo.rank
                              : ''
                          }`
                        : 'Unranked'}
                    </div>
                    <div className="text-sm text-slate-500 italic">
                      {summoner.ranked.solo?.lp} lp
                    </div>
                  </div>
                )}
              </div>
              {summoner.ranked.solo && (
                <div>
                  <div>
                    <div className="flex justify-between">
                      <div className="font-semibold text-emerald-700">
                        {summoner.ranked.solo.wins}W
                      </div>
                      <div className="font-semibold text-slate-700">
                        {summoner.ranked.solo.wr.toFixed(2)}%
                      </div>
                      <div className="font-semibold text-red-700">
                        {summoner.ranked.solo.losses}L
                      </div>
                    </div>
                    <div className="relative w-full h-2 bg-red-500">
                      <div
                        className="relative mx-auto h-full w-0.5 z-20 bg-slate-500"
                        aria-hidden="true"
                      />
                      <div
                        className="absolute inset-0 bg-emerald-500"
                        aria-hidden="true"
                        style={{ width: `${summoner.ranked.solo.wr}%` }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="bg-slate-50 p-4 rounded border border-slate-200">
              <div className="text-xs font-semibold text-slate-400 uppercase mb-4">
                Ranked FLEX
              </div>
              <div className="flex items-center mb-4">
                <div className="w-10 h-10 shrink-0 mr-3">
                  <img
                    className="rounded-full"
                    src={require(`../../images/ranked/emblems/${
                      summoner.ranked.flex?.tier.toLowerCase() || 'unranked'
                    }.png`)}
                    width="40"
                    height="40"
                    alt="Ranked Flex"
                  />
                </div>
                <div>
                  <div className="font-semibold text-slate-800">
                    {summoner.ranked.flex
                      ? `${summoner.ranked.flex.tier} ${
                          !isOneRank(summoner.ranked.flex.tier)
                            ? summoner.ranked.flex.rank
                            : ''
                        }`
                      : 'Unranked'}
                  </div>
                  <div className="text-sm text-slate-500 italic">
                    {summoner.ranked.flex?.lp
                      ? `${summoner.ranked.flex?.lp} lp`
                      : ''}
                  </div>
                </div>
              </div>
              {summoner.ranked.flex && (
                <div>
                  <div>
                    <div className="flex justify-between">
                      <div className="font-semibold text-emerald-700">
                        {summoner.ranked.flex.wins}W
                      </div>
                      <div className="font-semibold text-slate-700">
                        {summoner.ranked.flex.wr.toFixed(2)}%
                      </div>
                      <div className="font-semibold text-red-700">
                        {summoner.ranked.flex.losses}L
                      </div>
                    </div>
                    <div className="relative w-full h-2 bg-red-500">
                      <div
                        className="relative mx-auto h-full w-0.5 z-20 bg-slate-500"
                        aria-hidden="true"
                      />
                      <div
                        className="absolute inset-0 bg-emerald-500"
                        aria-hidden="true"
                        style={{ width: `${summoner.ranked.flex.wr}%` }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="bg-slate-50 p-4 rounded border border-slate-200">
              <div className="text-xs font-semibold text-slate-400 uppercase mb-4">
                Challenges
              </div>
              <div className="flex items-center mb-4">
                <div className="w-10 h-10 shrink-0 mr-3">
                  <img
                    className="rounded-full"
                    src={require(`../../images/ranked/emblems/${
                      summoner.challenges?.totalPoints.level.toLowerCase() ||
                      'unranked'
                    }.png`)}
                    width="40"
                    height="40"
                    alt="Ranked Flex"
                  />
                </div>
                <div>
                  <div className="font-semibold text-slate-800">
                    {summoner.challenges
                      ? summoner.challenges.totalPoints.level
                      : 'Unranked'}
                  </div>
                  <div className="text-sm text-slate-500 italic">
                    {summoner.challenges
                      ? `${summoner.challenges.totalPoints.current} / ${summoner.challenges.totalPoints.max}`
                      : 'N/A'}
                  </div>
                </div>
              </div>
              <ul className="space-y-3">
                {summoner.challenges?.preferences.challengeIds.map(
                  (id, key) => {
                    const challenge = summoner.challenges?.challenges.find(
                      c => c.challengeId === id,
                    );
                    const challengeData = getChallengeData(id);

                    if (!challenge || !challengeData) return <></>;

                    return (
                      <li key={key}>
                        <div className="flex items-center justify-between">
                          <div className="grow flex items-center">
                            <div className="relative mr-3">
                              <img
                                className="w-8 h-8 rounded-full"
                                src={`https://ddragon.leagueoflegends.com/cdn/img${
                                  challengeData.levelToIconPath[challenge.level]
                                }`}
                                width="32"
                                height="32"
                                alt={String(id)}
                              />
                            </div>
                            <div className="truncate">
                              <span className="text-sm font-medium text-slate-800">
                                {challengeData.name}
                              </span>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  },
                )}
              </ul>
              <div className="mt-4">
                <button className="btn-sm w-full bg-white border-slate-200 hover:border-slate-300 text-indigo-500 shadow-none cursor-not-allowed">
                  View All [Dev Disabled]
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
