import { IHistorySearch, IServer } from 'types/web';

export const setSummonersSearchHistory = (
  server: IServer,
  username: string,
): void => {
  const summonersHistory = JSON.parse(
    localStorage.getItem('summonersHistory') || '[]',
  ) as IHistorySearch[];

  if (
    summonersHistory.find(
      s => s.server === server.name && s.username === username,
    )
  )
    return;

  summonersHistory.push({ server: server.name, username });
  localStorage.setItem('summonersHistory', JSON.stringify(summonersHistory));
};

export const getSummonersSearchHistory = (): IHistorySearch[] | undefined => {
  const summonersHistory = JSON.parse(
    localStorage.getItem('summonersHistory') || '[]',
  ) as IHistorySearch[];

  return summonersHistory;
};
