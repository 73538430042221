import React from 'react';
import { useSelector } from 'react-redux';

import { createStructuredSelector } from 'reselect';
import {
  selectDDragonChampions,
  selectDDragonVersion,
} from 'actions/selectors';

import { IBannedChamption } from 'types/api/match';

interface IProps {
  ban: IBannedChamption;
}

const stateSelector = createStructuredSelector({
  version: selectDDragonVersion(),
  champions: selectDDragonChampions(),
});

export const BanCard: React.FC<IProps> = ({ ban }) => {
  const { version, champions } = useSelector(stateSelector);

  const getChampion = (id: number) => {
    return champions.find(c => c.key === String(id));
  };

  const champion = getChampion(ban.championId);

  return (
    <div
      className={`relative rounded-md border-2 w-9 h-9 ${
        ban.teamId === 100 ? 'border-blue-500' : 'border-red-500'
      }`}
    >
      <img
        className="rounded-md grayscale w-full h-full"
        src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/champion/${champion?.image.full}`}
        alt={champion?.name}
      />
    </div>
  );
};
