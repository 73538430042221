import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { setDDRagonData, setGames } from 'actions/actions';

import API from 'api';
import { firebaseApp } from 'firebaseApp';

import Main from 'containers/Main';
import Track from 'containers/Track';
import Live from 'containers/Live';

import Loader from 'components/Loader';
import SummonerContainer from 'containers/Summoner';

const api = new API();

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribeGames = firebaseApp.firestore.gamesSubscribe(games => {
      dispatch(setGames(games));
    });

    const getInitData = async () => {
      const ddragonVersion = await api.ddragon.getVersion();
      const ddragonChampions = await api.ddragon.getChampions();
      const ddragonSpells = await api.ddragon.getSummonerSpells();

      dispatch(setDDRagonData(ddragonVersion, ddragonChampions, ddragonSpells));
    };

    getInitData();

    return () => {
      unsubscribeGames();
    };
  }, []);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/summoner/:server/:username"
            element={<SummonerContainer />}
          />
          <Route path="/summoner" element={<Main />} />
          <Route path="/" element={<Main />} />
          <Route path="/live" element={<Live />} />
          <Route path="/track" element={<Track />} />
        </Routes>
      </BrowserRouter>
      <Loader />
    </>
  );
};

export default App;
