import { createSelector } from 'reselect';
import { initialState } from './reducer';

import { ApplicationRootState } from 'types';

export const selectGlobalDomain = (state: ApplicationRootState) =>
  state.global || initialState;

export const selectLoading = () =>
  createSelector(selectGlobalDomain, substate => substate.isLoading);

export const selectGames = () =>
  createSelector(selectGlobalDomain, substate => substate.games);

export const selectSummoners = () =>
  createSelector(selectGlobalDomain, substate => substate.summoners);

export const selectDDragonVersion = () =>
  createSelector(selectGlobalDomain, substate => substate.ddragon.version);

export const selectDDragonChampions = () =>
  createSelector(selectGlobalDomain, substate => substate.ddragon.champions);

export const selectDDragonSpells = () =>
  createSelector(selectGlobalDomain, substate => substate.ddragon.spells);
