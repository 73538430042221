import React from 'react';
import { useSelector } from 'react-redux';

import { createStructuredSelector } from 'reselect';
import {
  selectDDragonChampions,
  selectDDragonVersion,
} from 'actions/selectors';

import { IResponseSummoner } from 'types/response';

interface IProps {
  summoner: IResponseSummoner;
}

const stateSelector = createStructuredSelector({
  champions: selectDDragonChampions(),
  version: selectDDragonVersion(),
});

export const SummonerMastery: React.FC<IProps> = ({ summoner }) => {
  const { champions, version } = useSelector(stateSelector);

  return (
    <div className="w-full hidden xl:block xl:w-72">
      <div className="lg:sticky top-0 lg:h-[calc(100vh-64px)] lg:overflow-x-hidden lg:overflow-y-auto no-scrollbar">
        <div className="md:py-8">
          <div className="space-y-4">
            <div className="bg-slate-50 p-4 rounded border border-slate-200">
              <div className="text-xs font-semibold text-slate-400 uppercase mb-4">
                Mastery
              </div>
              <ul className="space-y-3">
                {summoner.mastery
                  .sort((a, b) => b.championPoints - a.championPoints)
                  .map((mastery, index) => {
                    const champion = champions.find(
                      c => Number(c.key) === mastery.championId,
                    );
                    if (!champion || index >= 14) return;

                    return (
                      <li key={index}>
                        <div className="flex items-center justify-between">
                          <div className="grow flex items-center">
                            <div className="relative mr-3">
                              <img
                                className="w-8 h-8 rounded-md"
                                src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/champion/${champion.image.full}`}
                                width="32"
                                height="32"
                                alt={champion.name}
                              />
                            </div>
                            <div className="truncate">
                              <span className="text-sm font-medium text-slate-800">
                                {champion.name}
                              </span>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
              </ul>
              <div className="mt-4">
                <button className="btn-sm w-full bg-white border-slate-200 hover:border-slate-300 text-indigo-500 shadow-none cursor-not-allowed">
                  View All [Dev Disabled]
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
