import { FirebaseApp, initializeApp } from 'firebase/app';

import FirestoreService from 'services/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyB33NIEqoEEkBO7MOePlWpm1T5qjHvDpuQ',
  authDomain: 'alegue-3c8e4.firebaseapp.com',
  projectId: 'alegue-3c8e4',
  storageBucket: 'alegue-3c8e4.appspot.com',
  messagingSenderId: '800264584797',
  appId: '1:800264584797:web:e624d0e7dcc95a549f57b4',
  measurementId: 'G-N9HJPMVT4K',
};

export class Firebase {
  private firebaseApp: FirebaseApp = initializeApp(firebaseConfig);

  public firestore: FirestoreService = new FirestoreService(this.firebaseApp);
}

export const firebaseApp = new Firebase();
