import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import Flag from 'react-flagkit';

import { Transition } from '@tailwindui/react';

import { IServer } from 'types/web';

interface IServerProps {
  selectedServer: IServer;
  setSelectedServer: Dispatch<SetStateAction<IServer>>;
  servers: IServer[];
}

export const ServersDropdown: React.FC<IServerProps> = ({
  selectedServer,
  setSelectedServer,
  servers,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const container = useRef(null);
  const dropdown = useRef(null);

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (
        !dropdownOpen ||
        //@ts-ignore
        dropdown.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  useEffect(() => {
    setDropdownOpen(false);
  }, [selectedServer]);

  return (
    <div className="relative w-1/5">
      <div
        ref={container}
        className="form-select px-5 h-full inline-flex justify-center w-full items-center group cursor-pointer"
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <div className="flex text-center items-center justify-center w-full">
          {selectedServer.region.length ? (
            <Flag
              country={selectedServer.region}
              className="mr-2 hidden sm:block"
            />
          ) : (
            <img
              className="h-4 mr-2 hidden sm:block"
              width={24}
              src={require('../../images/no_flag.png')}
            />
          )}
          <div className="flex items-center">
            <span className="truncate text-sm font-medium duration-700 group-hover:text-slate-800">
              {selectedServer.name}
            </span>
          </div>
        </div>
      </div>

      <Transition
        className="origin-top-right z-10 absolute top-full w-full bg-white border border-slate-200 py-1.5 rounded shadow-lg overflow-hidden mt-1"
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterFrom="opacity-0 -translate-y-2"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          <ul>
            {servers.map((server, key) => {
              return (
                <li
                  className="font-medium text-sm text-indigo-500 hover:bg-indigo-200 flex items-center py-1 px-3 cursor-pointer"
                  key={key}
                  onClick={() => setSelectedServer(server)}
                >
                  {server.region.length ? (
                    <Flag
                      country={server.region}
                      className="hidden mr-2 sm:block"
                    />
                  ) : (
                    <img
                      className="h-4 mr-2 hidden sm:block"
                      width={24}
                      src={require('../../images/no_flag.png')}
                    />
                  )}
                  <div className="flex items-center">
                    <span className="truncate text-sm font-medium">
                      {server.name}
                    </span>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </Transition>
    </div>
  );
};
