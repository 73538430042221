import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { setLoading } from 'actions/actions';

import { Header } from 'components/Header';
import Sidebar from 'components/Sidebar';
import { SummonerComponent } from 'components/Summoner';

import API from 'api';

import { IDDChallenges } from 'types/api/ddragon';
import { IResponseSummoner } from 'types/response';

const api = new API();

const SummonerContainer = () => {
  const dispatch = useDispatch();

  const { server, username } = useParams();

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedSummoner, setSelectedSummoner] = useState<
    IResponseSummoner | undefined
  >();
  const [challengesData, setChallengesData] = useState<IDDChallenges[]>([]);

  useEffect(() => {
    if (server?.length && username?.length) {
      const getSummoner = async () => {
        dispatch(setLoading(true));
        const summoner = await api.league.getSummoner(
          username,
          server.toUpperCase(),
        );

        const challenges = await api.ddragon.getChallenges();
        setChallengesData(challenges);
        setSelectedSummoner(summoner);
        dispatch(setLoading(false));
      };

      getSummoner();
    } else setSelectedSummoner(undefined);
  }, [server, username]);

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <Header
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          searchType={'summoner'}
        />
        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 md:py-0 w-full max-w-9xl mx-auto">
            {selectedSummoner && (
              <SummonerComponent
                summoner={selectedSummoner}
                challengesData={challengesData}
              />
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

export default SummonerContainer;
