import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectGames } from 'actions/selectors';

import Sidebar from 'components/Sidebar';
import { LiveSidebar } from 'components/Live/Sidebar';
import { LiveBody } from 'components/Live/Body';
import { Header } from 'components/Header';

import { IDatabaseGame } from 'types/database/league';

const stateSelector = createStructuredSelector({
  games: selectGames(),
});

const Track = () => {
  const { games } = useSelector(stateSelector);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedGameSidebarOpen, setSelectedGameSidebarOpen] = useState(false);
  const [liveSidebarOpen, setLiveSidebarOpen] = useState(false);

  const [selectedGame, setSelectedGame] = useState<IDatabaseGame | undefined>();

  useEffect(() => {
    if (!selectedGame) return;

    if (!games.find(g => g.gameId === selectedGame.gameId))
      selectedGame.isOver = true;
  }, [games]);

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <Header
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          searchType={'live'}
        />
        <main className="h-full">
          <div className="relative flex h-full">
            <LiveSidebar
              open={liveSidebarOpen}
              setOpen={setLiveSidebarOpen}
              games={games}
              selectedGame={selectedGame}
              setSelectedGame={setSelectedGame}
            />
            <LiveBody
              selectedGameSidebarOpen={selectedGameSidebarOpen}
              setSelectedGameSidebarOpen={setSelectedGameSidebarOpen}
              selectedGame={selectedGame}
            />
          </div>
        </main>
      </div>
    </div>
  );
};

export default Track;
