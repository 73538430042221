import { Transition } from '@tailwindui/react';
import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';

interface IProps {
  id: number;
  modalOpen: boolean;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
}

export const GameEndModal: React.FC<IProps> = ({
  id,
  modalOpen,
  setModalOpen,
}) => {
  const modalContent = useRef(null);

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (
        !modalOpen ||
        //@ts-ignore
        modalContent.current.contains(target)
      )
        return;
      setModalOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!modalOpen || keyCode !== 27) return;
      setModalOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
    <>
      <Transition
        className="fixed inset-0 bg-slate-900 bg-opacity-30 z-50 transition-opacity"
        show={modalOpen}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease-out duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        aria-hidden="true"
      >
        <Transition
          id={String(id)}
          className="fixed inset-0 z-50 overflow-hidden flex items-center my-4 justify-center px-4 sm:px-6"
          role="dialog"
          aria-modal="true"
          show={modalOpen}
          enter="transition ease-in-out duration-200"
          enterFrom="opacity-0 translate-y-4"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in-out duration-200"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-4"
        >
          <div
            ref={modalContent}
            className="bg-white rounded shadow-lg overflow-auto max-w-lg w-full max-h-full"
          >
            <div className="p-6">
              <div className="relative">
                <div className="mb-2 text-center">
                  <div className="text-lg font-semibold text-slate-800">
                    The game is over
                  </div>
                </div>
                <div className="text-center">
                  <div className="text-sm mb-5">
                    You can view the detailed statistics of this game by
                    clicking on the button below
                  </div>
                  <div className="space-y-3">
                    <button className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white">
                      View the game #{id}
                    </button>
                    <div>
                      <a
                        className="font-medium text-sm text-indigo-500 hover:text-indigo-600"
                        href="#0"
                        onClick={e => {
                          e.preventDefault();
                          setModalOpen(true);
                        }}
                      >
                        Close
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </Transition>
    </>
  );
};

/*
                    <div
                        ref={modalContent}
                        className="bg-white rounded shadow-lg overflow-auto max-w-lg w-full max-h-full"
                    >
                        <div className="mb-2 text-center">
                            <div className="inline-flex mb-2">
                                <img src={"AnnouncementIcon"} width="80" height="80" alt="Announcement" />
                            </div>
                            <div className="text-lg font-semibold text-slate-800">You Unlocked Level 2!</div>
                        </div>
                        <div className="text-center">
                            <div className="text-sm mb-5">
                                Semper eget duis at tellus at urna condimentum mattis pellentesque lacus suspendisse faucibus interdum.
                            </div>
                            <div className="space-y-3">
                                <button className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white">Claim your Reward -&gt;</button>
                                <div>
                                    <a className="font-medium text-sm text-indigo-500 hover:text-indigo-600" href="#0" onClick={(e) => { e.preventDefault(); setModalOpen(true); }}>Not Now!</a>
                                </div>
                            </div>
                        </div>
*/
