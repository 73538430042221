import React from 'react';

import { SummonerData } from './SummonerData';

import { IDDChallenges } from 'types/api/ddragon';
import { IResponseSummoner } from 'types/response';
import { SummonerGames } from './SummonerGames';
import { SummonerMastery } from './SummonerMastery';

interface IProps {
  summoner: IResponseSummoner;
  challengesData: IDDChallenges[];
}

export const SummonerComponent: React.FC<IProps> = ({
  summoner,
  challengesData,
}) => {
  return (
    <div className="xl:flex">
      <div className="md:flex flex-1">
        <SummonerData summoner={summoner} challengesData={challengesData} />
        <SummonerGames
          summonerId={summoner.data.id}
          matches={summoner.matches}
        />
        <SummonerMastery summoner={summoner} />
      </div>
    </div>
  );
};
