import React, { Dispatch, SetStateAction } from 'react';

import { ServersDropdown } from 'components/Dropdown/Servers';
import { UsernamesDropdown } from 'components/Dropdown/Username';

import { IServer } from 'types/web';

interface IProps {
  selectedServer: IServer;
  setSelectedServer: Dispatch<SetStateAction<IServer>>;
  username: string;
  setUsername: Dispatch<SetStateAction<string>>;
  servers: IServer[];
}

export const SummonerSearch: React.FC<IProps> = ({
  selectedServer,
  setSelectedServer,
  username,
  setUsername,
  servers,
}) => {
  return (
    <>
      <ServersDropdown
        selectedServer={selectedServer}
        setSelectedServer={setSelectedServer}
        servers={servers}
      />
      <UsernamesDropdown
        selectedServer={selectedServer}
        username={username}
        setUsername={setUsername}
      />
    </>
  );
};
