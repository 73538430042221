import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Transition } from '@tailwindui/react';

import { IQueueType } from 'types/web';

interface IServerProps {
  selectedQueue: IQueueType;
  setSelectedQueue: Dispatch<SetStateAction<IQueueType>>;
  queueType: IQueueType[];
}

export const QueueTypeDropdown: React.FC<IServerProps> = ({
  selectedQueue,
  setSelectedQueue,
  queueType,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const container = useRef(null);
  const dropdown = useRef(null);

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (
        !dropdownOpen ||
        //@ts-ignore
        dropdown.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  useEffect(() => {
    setDropdownOpen(false);
  }, [selectedQueue]);

  return (
    <div className="relative w-1/3">
      <div
        ref={container}
        className="form-select p-2 h-full inline-flex justify-center w-full items-center group cursor-pointer"
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <div className="flex text-center items-center justify-center">
          <div className="flex items-center">
            <span className="truncate text-sm font-medium duration-700 text-indigo-500">
              {selectedQueue.name}
            </span>
          </div>
        </div>
      </div>

      <Transition
        className="origin-top-right z-10 absolute top-full w-full bg-white border border-slate-200 py-1.5 rounded shadow-lg overflow-hidden mt-1"
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterFrom="opacity-0 -translate-y-2"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          <ul>
            {queueType.map((queue, key) => {
              return (
                <li
                  className="font-medium text-sm text-indigo-500 hover:bg-indigo-200 flex items-center justify-center py-1 px-3 cursor-pointer"
                  key={key}
                  onClick={() => setSelectedQueue(queue)}
                >
                  <div className="flex items-center">
                    <span className="truncate ml-2 text-sm font-medium">
                      {queue.name}
                    </span>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </Transition>
    </div>
  );
};
