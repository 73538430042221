import superagent from 'superagent';
import { IResponseSummoner } from 'types/response';

export default class League {
  private base_url = 'https://europe-west2-alegue-3c8e4.cloudfunctions.net/api';

  public async getSummoner(
    name: string,
    server: string,
  ): Promise<IResponseSummoner | undefined> {
    try {
      const response = await superagent
        .get(`${this.base_url}/getSummoner`)
        .query({ name: name })
        .query({ server: server });

      if (response.status !== 200) return;
      return response.body as IResponseSummoner;
    } catch (error) {
      return;
    }
  }

  public async trackSummoner(name: string, server: string): Promise<boolean> {
    try {
      const response = await superagent
        .post(`${this.base_url}/trackSummoner`)
        .query({ name: name })
        .query({ server: server });

      if (response.status !== 200) return false;
      return true;
    } catch (error) {
      return false;
    }
  }
}
