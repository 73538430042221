import moment from 'moment';

import {
  selectDDragonVersion,
  selectDDragonChampions,
} from 'actions/selectors';
import { QueueTypeDropdown } from 'components/Dropdown/QueueType';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { createStructuredSelector } from 'reselect';
import { IResponseMatche } from 'types/response';
import { IQueueType } from 'types/web';

import { queueType } from 'utils/constants';
import queues from '../../utils/queues.json';

interface IProps {
  summonerId: string;
  matches: IResponseMatche[];
}

const stateSelector = createStructuredSelector({
  version: selectDDragonVersion(),
  champions: selectDDragonChampions(),
});

export const SummonerGames: React.FC<IProps> = ({ summonerId, matches }) => {
  const { version, champions } = useSelector(stateSelector);

  const [selectedQueue, setSelectedQueue] = useState<IQueueType>(queueType[0]);

  const getSummonerParticipant = (match: IResponseMatche) => {
    return match.data.participants.find(p => p.summonerId === summonerId);
  };

  const getChampion = (id: number) => {
    return champions.find(c => c.key === String(id));
  };

  return (
    <div className="flex-1 md:ml-8 xl:mx-4 2xl:mx-8">
      <div className="md:py-8">
        <div className="mb-4">
          <div className="w-full flex flex-wrap -space-x-px">
            <QueueTypeDropdown
              queueType={queueType}
              selectedQueue={selectedQueue}
              setSelectedQueue={setSelectedQueue}
            />
            <button className="btn grow w-1/3 bg-white border-slate-200 text-indigo-500 rounded-none first:rounded-l last:rounded-r">
              All Queues
            </button>
            <button className="btn grow w-1/3 bg-white border-slate-200 text-indigo-500 rounded-none first:rounded-l last:rounded-r">
              All Queues
            </button>
          </div>
        </div>

        <div className="space-y-2">
          {matches.map((match, index) => {
            const participant = getSummonerParticipant(match);
            if (!participant) return <></>;

            const champion = getChampion(participant.championId);
            const queue = queues.find(q => q.queueId === match.data.queueId);

            return (
              <div
                className={`shadow-md rounded border ${
                  participant.win
                    ? 'border-blue-300 bg-blue-50'
                    : 'border-red-300 bg-red-50'
                } p-5`}
                key={index}
              >
                <div className="flex flex-start space-x-4">
                  <div className="shrink-0 mt-1.5">
                    <img
                      className="w-11 h-11 rounded-md"
                      src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/champion/${champion?.image.full}`}
                      width="44"
                      height="44"
                      alt={champion?.name}
                    />
                  </div>

                  <div className="grow">
                    <header className="flex font-semibold text-slate-800 mb-2">
                      <div className="flex">
                        <p
                          className={`flex mr-1 font-medium ${
                            participant.win ? 'text-blue-500' : 'text-red-500'
                          }`}
                        >
                          {participant.win ? 'Victory' : 'Defeat'}
                        </p>
                        <p className="flex items-center text-slate-600">
                          {queue?.description?.replace('games', '')}
                        </p>
                      </div>
                    </header>
                    <footer className="flex flex-wrap text-sm">
                      <div className="flex items-center after:block after:content-['·'] last:after:content-[''] after:text-sm after:text-slate-400 after:px-2">
                        <span className="text-slate-500">
                          {moment
                            .utc(match.data.gameDuration * 1000)
                            .format('mm:ss')}
                        </span>
                      </div>
                      <div className="flex items-center after:block after:content-['·'] last:after:content-[''] after:text-sm after:text-slate-400 after:px-2">
                        <span className="text-slate-500">
                          {moment(match.data.gameEndTimestamp).fromNow()}
                        </span>
                      </div>
                    </footer>
                  </div>
                  <div className="grow">
                    <header className="flex font-semibold text-slate-800 mb-2">
                      <div className="flex">
                        <p className="flex items-center after:block after:content-['/'] last:after:content-[''] after:text-sm after:text-slate-400 after:px-1 text-green-600">
                          {participant.kills}
                        </p>
                        <p className="flex items-center after:block after:content-['/'] last:after:content-[''] after:text-sm after:text-slate-400 after:px-1 text-red-600">
                          {participant.deaths}
                        </p>
                        <p className="flex items-center after:block after:content-['/'] last:after:content-[''] after:text-sm after:text-slate-400 after:px-1 text-blue-600">
                          {participant.assists}
                        </p>
                      </div>
                    </header>
                    <footer className="flex font-semibold text-slate-800">
                      <div className="flex">
                        <p className="flex items-center after:block after:content-['/'] last:after:content-[''] after:text-sm after:text-slate-400 after:px-1 text-slate-600">
                          {participant.totalMinionsKilled +
                            participant.neutralMinionsKilled}{' '}
                          CS
                        </p>
                        <p className="flex items-center after:block after:content-['/'] last:after:content-[''] after:text-sm after:text-slate-400 after:px-1 text-slate-600">
                          {participant.kills}% KP
                        </p>
                        <p className="flex items-center after:block after:content-['/'] last:after:content-[''] after:text-sm after:text-slate-400 after:px-1 text-slate-600">
                          {participant.visionScore} VS
                        </p>
                      </div>
                    </footer>
                  </div>
                  <div className="shrink-0">
                    <button className="text-xs font-semibold text-center h-12 w-12 border border-indigo-400 rounded-sm flex flex-col justify-center items-center outline outline-2 outline-indigo-100">
                      <svg
                        className="inline-flex fill-indigo-500 mt-1.5 mb-1.5"
                        width="12"
                        height="6"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="m0 6 6-6 6 6z" />
                      </svg>
                      <div></div>
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="mt-6 text-right">
          <nav
            className="inline-flex"
            role="navigation"
            aria-label="Navigation"
          >
            <ul className="flex justify-center">
              <li className="ml-3 first:ml-0">
                <a
                  className="btn bg-white border-slate-200 text-slate-300 cursor-not-allowed"
                  href="#0"
                >
                  &lt;- Previous
                </a>
              </li>
              <li className="ml-3 first:ml-0">
                <a
                  className="btn bg-white border-slate-200 hover:border-slate-300 text-indigo-500"
                  href="#0"
                >
                  Next -&gt;
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};
