import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FaHome, FaRecordVinyl } from 'react-icons/fa';
import { useSelector } from 'react-redux';

import { createStructuredSelector } from 'reselect';

import { selectGames } from 'actions/selectors';

import Link from './Link';

const stateSelector = createStructuredSelector({
  games: selectGames(),
});

interface IProps {
  sidebarOpen: boolean;
  setSidebarOpen: Dispatch<SetStateAction<boolean>>;
}

const Sidebar: React.FC<IProps> = ({ sidebarOpen, setSidebarOpen }) => {
  const { games } = useSelector(stateSelector);

  const sidebar = useRef(null);
  const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true',
  );

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (
        !sidebarOpen ||
        //@ts-ignore
        sidebar.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  useEffect(() => {
    localStorage.setItem('sidebar-expanded', sidebarExpanded.toString());
    if (sidebarExpanded) {
      document.querySelector('body')?.classList.add('sidebar-expanded');
    } else {
      document.querySelector('body')?.classList.remove('sidebar-expanded');
    }
  }, [sidebarExpanded]);

  return (
    <div>
      <div
        className={`fixed inset-0 bg-slate-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
          sidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
        aria-hidden="true"
      ></div>

      <div
        id="sidebar"
        ref={sidebar}
        className={`flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 lg:w-20 lg:sidebar-expanded:!w-64 2xl:!w-64 shrink-0 p-4 transition-all ${
          sidebarOpen ? 'translate-x-0' : '-translate-x-64'
        } duration-700 ease-in-out shadow-md bg-slate-800`}
      >
        <div className="flex justify-between mb-3">
          <button
            className="lg:hidden text-slate-500 hover:text-slate-400"
            aria-controls="sidebar"
            aria-expanded="false"
            onClick={() => setSidebarOpen(false)}
          >
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
          <a aria-current="page" className="block active" href="/">
            <img
              className="w-3/4 mx-auto mb-3 lg:mx-auto"
              src={''}
              alt="Logo"
            />
          </a>
        </div>

        <div className="space-y-8">
          <div>
            <h3 className="text-xs uppercase text-slate-200 font-semibold pl-3">
              <span
                className="hidden lg:block lg:sidebar-expanded:hidden 2xl:hidden text-center w-6"
                aria-hidden="true"
              >
                •••
              </span>
            </h3>
            <ul className="mt-3">
              <Link
                icon={FaHome}
                path="/summoner"
                sidebarExpanded={sidebarExpanded}
                title={'Summoners'}
              />
              <Link
                icon={FaRecordVinyl}
                path="/track"
                sidebarExpanded={sidebarExpanded}
                title={'Tracked Games'}
                badgeCount={games.length}
              />
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
