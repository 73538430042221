import { IQueueType, IServer } from 'types/web';

export const servers: IServer[] = [
  {
    name: 'EUNE',
    region: 'EU',
  },
  {
    name: 'EUW',
    region: 'EU',
  },
  {
    name: 'NA',
    region: '',
  },
  {
    name: 'BR',
    region: 'BR',
  },
  {
    name: 'LAN',
    region: '',
  },
  {
    name: 'LAS',
    region: '',
  },
  {
    name: 'OCE',
    region: '',
  },
  {
    name: 'KR',
    region: 'KR',
  },
  {
    name: 'RU',
    region: 'RU',
  },
  {
    name: 'TR',
    region: 'TR',
  },
  {
    name: 'JP',
    region: 'JP',
  },
];

export const queueType: IQueueType[] = [
  {
    name: 'All Queues',
    id: 0,
  },
  {
    name: 'Normal (Blind Pick)',
    id: 0,
  },
  {
    name: 'Normal (Draft Pick)',
    id: 0,
  },
  {
    name: 'Ranked (Solo/Duo)',
    id: 0,
  },
  {
    name: 'Ranked (Flex)',
    id: 0,
  },
  {
    name: 'ARAM',
    id: 0,
  },
  {
    name: 'Clash',
    id: 0,
  },
  {
    name: 'Custom',
    id: 0,
  },
  {
    name: 'One For All',
    id: 0,
  },
  {
    name: 'Nexus Blitz',
    id: 0,
  },
  {
    name: 'ARURF',
    id: 0,
  },
  {
    name: 'Ultimate Spellbook',
    id: 0,
  },
];
