export enum ERank {
  BRONZE = 'BRONZE',
  SILVER = 'SELVER',
  GOLD = 'GOLD',
  PLATINUM = 'PLATINUM',
  DIAMOND = 'DIAMOND',
  MASTER = 'MASTER',
  GRANDMASTER = 'GRANDMASTER',
  CHALLENGER = 'CHALLENGER',
}

export enum EServer {
  EUNE = 'EUNE',
  EUW = 'EUW',
  NA = 'NA',
  BR = 'BR',
  LAN = 'LAN',
  LAS = 'LAS',
  OCE = 'OCE',
  KR = 'KR',
  RU = 'RU',
  TR = 'TR',
  JP = 'JP',
}

export interface IRanked {
  leagueId: string;
  queueType: 'RANKED_FLEX_SR' | 'RANKED_SOLO_5x5';
  tier: ERank;
  rank: string;
  summonerId: string;
  summonerName: string;
  leaguePoints: number;
  wins: number;
  losses: number;
  veteran: boolean;
  inactive: boolean;
  freshBlood: boolean;
  hotStreak: boolean;
}
