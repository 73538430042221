import { FirebaseApp } from 'firebase/app';
import {
  collection,
  CollectionReference,
  DocumentData,
  Firestore,
  getFirestore,
  onSnapshot,
  query,
} from 'firebase/firestore';
import { IDatabaseGame } from 'types/database/league';
import { IResponseSummoner } from 'types/response';

export default class FirestoreService {
  private firestore: Firestore;

  private gamesReference: CollectionReference<DocumentData>;
  private summonersReference: CollectionReference<DocumentData>;

  constructor(firebaseApp: FirebaseApp) {
    this.firestore = getFirestore(firebaseApp);

    this.gamesReference = collection(this.firestore, 'games');
    this.summonersReference = collection(this.firestore, 'summoners');
  }

  public gamesSubscribe = (onChange: (games: IDatabaseGame[]) => void) => {
    const q = query(this.gamesReference);

    const unsubscribe = onSnapshot(q, snapshot => {
      onChange(snapshot.docs.map(s => s.data() as IDatabaseGame));
    });

    return unsubscribe;
  };

  public summonersSubscribe = (
    onChange: (games: IResponseSummoner[]) => void,
  ) => {
    const q = query(this.summonersReference);

    const unsubscribe = onSnapshot(q, snapshot => {
      onChange(snapshot.docs.map(s => s.data() as IResponseSummoner));
    });

    return unsubscribe;
  };
}
