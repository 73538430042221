import React, { useState } from 'react';
import { useNavigate } from 'react-router';

import Sidebar from 'components/Sidebar';
import { SummonerSearch } from 'components/Search/Summoner';
import { Header } from 'components/Header';

import { servers } from 'utils/constants';
import { setSummonersSearchHistory } from 'utils/storage';

import { IServer } from 'types/web';

const Main = () => {
  const navigate = useNavigate();

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [selectedUsername, setSelectedUsername] = useState<string>('');
  const [selectedServer, setSelectedServer] = useState<IServer>(servers[0]);

  const handleSubmit = e => {
    e.preventDefault();

    if (!selectedServer || !selectedUsername.length) return;

    setSummonersSearchHistory(selectedServer, selectedUsername);
    navigate(
      `/summoner/${selectedServer.name.toLowerCase()}/${selectedUsername}`,
    );
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <Header
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          searchType={'summoner'}
        />
        <main>
          <div className="relative flex flex-col items-center justify-center px-4 sm:px-6 lg:px-8 py-8 lg:py-16 bg-indigo-500">
            <div className="absolute pointer-events-none" aria-hidden="true">
              <svg width="512" height="512" xmlns="http://www.w3.org/2000/svg">
                <defs>
                  <radialGradient
                    cx="50%"
                    cy="50%"
                    fx="50%"
                    fy="50%"
                    r="50%"
                    id="ill-a"
                  >
                    <stop stopColor="#FFF" offset="0%" />
                    <stop stopColor="#FFF" stopOpacity="0" offset="100%" />
                  </radialGradient>
                </defs>
                <circle
                  style={{ mixBlendMode: 'overlay' }}
                  cx="588"
                  cy="650"
                  r="256"
                  transform="translate(-332 -394)"
                  fill="url(#ill-a)"
                  fillRule="evenodd"
                  opacity=".48"
                />
              </svg>
            </div>
            <div className="absolute pointer-events-none" aria-hidden="true">
              <svg
                width="1280"
                height="361"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
              >
                <defs>
                  <linearGradient
                    x1="50%"
                    y1="0%"
                    x2="50%"
                    y2="100%"
                    id="ill2-b"
                  >
                    <stop stopColor="#A5B4FC" offset="0%" />
                    <stop stopColor="#818CF8" offset="100%" />
                  </linearGradient>
                  <linearGradient
                    x1="50%"
                    y1="24.537%"
                    x2="50%"
                    y2="100%"
                    id="ill2-c"
                  >
                    <stop stopColor="#4338CA" offset="0%" />
                    <stop stopColor="#6366F1" stopOpacity="0" offset="100%" />
                  </linearGradient>
                  <path id="ill2-a" d="m64 0 64 128-64-20-64 20z" />
                  <path id="ill2-e" d="m40 0 40 80-40-12.5L0 80z" />
                </defs>
                <g fill="none" fillRule="evenodd">
                  <g transform="rotate(51 -92.764 293.763)">
                    <mask id="ill2-d" fill="#fff">
                      <use xlinkHref="#ill2-a" />
                    </mask>
                    <use fill="url(#ill2-b)" xlinkHref="#ill2-a" />
                    <path
                      fill="url(#ill2-c)"
                      mask="url(#ill2-d)"
                      d="M64-24h80v152H64z"
                    />
                  </g>
                  <g transform="rotate(-51 618.151 -940.113)">
                    <mask id="ill2-f" fill="#fff">
                      <use xlinkHref="#ill2-e" />
                    </mask>
                    <use fill="url(#ill2-b)" xlinkHref="#ill2-e" />
                    <path
                      fill="url(#ill2-c)"
                      mask="url(#ill2-f)"
                      d="M40.333-15.147h50v95h-50z"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <div className="relative w-full max-w-2xl mx-auto text-center">
              <div className="mb-5">
                <h1 className="text-2xl md:text-3xl text-white font-bold">
                  Enter Summoner Username
                </h1>
              </div>
              <form className="relative" onSubmit={e => handleSubmit(e)}>
                <label htmlFor="action-search" className="sr-only">
                  Search
                </label>
                <div className="flex flex-row space-x-2">
                  <SummonerSearch
                    selectedServer={selectedServer}
                    setSelectedServer={setSelectedServer}
                    username={selectedUsername}
                    setUsername={setSelectedUsername}
                    servers={servers}
                  />
                  <button
                    type={'submit'}
                    className="btn-lg bg-white border border-slate-200 hover:border-slate-300"
                  >
                    Search
                  </button>
                </div>
              </form>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Main;
