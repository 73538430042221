import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { createStructuredSelector } from 'reselect';
import {
  selectDDragonChampions,
  selectDDragonVersion,
} from 'actions/selectors';

import { IDDSpell } from 'types/api/ddragon';
import { EServer, ERank } from 'types/api/ranked';
import { IParticipant } from 'types/api/match';

interface IProps {
  participant: IParticipant;
  server: EServer;
  summonerSpells: IDDSpell[];
}

const stateSelector = createStructuredSelector({
  version: selectDDragonVersion(),
  champions: selectDDragonChampions(),
});

export const ParticipantCard: React.FC<IProps> = ({ participant, server }) => {
  const { version, champions } = useSelector(stateSelector);

  const getChampion = (id: number) => {
    return champions.find(c => c.key === String(id));
  };

  const isOneRank = (rank: ERank) => {
    return (
      rank === ERank.CHALLENGER ||
      rank === ERank.GRANDMASTER ||
      rank === ERank.MASTER
    );
  };

  const champion = getChampion(participant.championId);

  return (
    <div className="col-span-2 bg-white shadow-lg rounded-md border border-slate-200 w-full">
      <div className="flex flex-col h-full">
        <div
          className={`border-y border-b-2 text-sm border-slate-200 text-center p-1 ${
            participant.teamId === 100
              ? 'border-b-blue-500'
              : 'border-b-red-500'
          }`}
        >
          <Link
            className={`inline-flex ${
              participant.teamId === 100
                ? 'text-blue-500 hover:text-blue-600'
                : 'text-red-500 hover:text-red-600'
            }`}
            to={`/summoner/${server.toLowerCase()}/${encodeURI(
              participant.summonerName,
            )}`}
          >
            <h2 className="leading-snug justify-center font-semibold">
              {participant.summonerName}
            </h2>
          </Link>
        </div>
        <div className="grow p-5">
          <div className="flex justify-between items-start">
            <header>
              <div className="flex mb-2">
                <div className="relative inline-flex items-start mr-3">
                  <img
                    className="rounded-md"
                    src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/champion/${champion?.image.full}`}
                    width="48"
                    height="48"
                    alt={champion?.name}
                  />
                </div>
                <div className="mt-1 pr-1 text-sm">
                  <div className="flex items-center">
                    <span>WR</span>
                    <span className="text-sm font-medium text-slate-400 -mt-0.5 mr-1"></span>
                  </div>
                  <div className="flex items-center">
                    <span>{champion?.name}</span>
                    <span className="text-sm font-medium text-slate-400 -mt-0.5 mr-1"></span>
                  </div>
                </div>
              </div>
            </header>
          </div>
          <div className="mt-2">
            <div className="text-sm"></div>
          </div>
        </div>

        {participant.ranked.solo ? (
          <div className="border-t text-sm border-slate-200">
            <div className="block text-center text-indigo-500 hover:text-indigo-600 font-medium px-3 py-4">
              <div className="flex">
                <div className="flex mb-2">
                  <div className="relative inline-flex items-center justify-center mr-5">
                    <img
                      className="rounded-md"
                      src={require(`../../images/ranked/emblems/${participant.ranked.solo.tier.toLowerCase()}.png`)}
                      width="50"
                      height="50"
                      alt={'Rank'}
                    />
                  </div>
                  <div className="mt-1 pr-1">
                    <div className="inline-flex w-full text-slate-800 hover:text-slate-900">
                      <h2 className="text-xs font-bold leading-snug justify-center">
                        {participant.ranked.solo.tier}{' '}
                        {!isOneRank(participant.ranked.solo.tier) &&
                          participant.ranked.solo.rank}{' '}
                        ({participant.ranked.solo.lp} lp)
                      </h2>
                    </div>
                    <div className="flex flex-col items-start">
                      <span>
                        {participant.ranked.solo.wins +
                          participant.ranked.solo.losses}{' '}
                        games
                      </span>
                      <span className="font-medium text-slate-400">
                        {participant.ranked.solo.wr.toFixed(1)}% WR
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="border-t text-sm border-slate-200">
            <div className="block text-center text-indigo-500 hover:text-indigo-600 font-medium px-3 py-4">
              <div className="flex">
                <div className="flex mb-2">
                  <div className="relative inline-flex items-center justify-center mr-5">
                    <img
                      className="rounded-md"
                      src={require(`../../images/ranked/emblems/unranked.png`)}
                      width="50"
                      height="50"
                      alt={'Rank'}
                    />
                  </div>
                  <div className="mt-1 pr-1">
                    <div className="inline-flex w-full text-slate-800 hover:text-slate-900">
                      <h2 className="leading-snug justify-center font-semibold">
                        Unranked
                      </h2>
                    </div>
                    <div className="flex flex-col items-start">
                      <span>N/A</span>
                      <span className="font-medium text-slate-400">N/A</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {participant.ranked.flex ? (
          <div className="border-t text-sm border-slate-200">
            <div className="block text-center text-indigo-500 hover:text-indigo-600 font-medium px-3 py-4">
              <div className="flex">
                <div className="flex mb-2">
                  <div className="relative inline-flex items-center justify-center mr-5">
                    <img
                      className="rounded-md"
                      src={require(`../../images/ranked/emblems/${participant.ranked.flex.tier.toLowerCase()}.png`)}
                      width="50"
                      height="50"
                      alt={'Rank'}
                    />
                  </div>
                  <div className="mt-1 pr-1">
                    <div className="inline-flex w-full text-slate-800 hover:text-slate-900">
                      <h2 className="text-xs font-bold leading-snug justify-center">
                        {participant.ranked.flex.tier}{' '}
                        {!isOneRank(participant.ranked.flex.tier) &&
                          participant.ranked.flex.rank}{' '}
                        ({participant.ranked.flex.lp} lp)
                      </h2>
                    </div>
                    <div className="flex flex-col items-start">
                      <span>
                        {participant.ranked.flex.wins +
                          participant.ranked.flex.losses}{' '}
                        games
                      </span>
                      <span className="font-medium text-slate-400">
                        {participant.ranked.flex.wr.toFixed(1)}% WR
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="border-t text-sm border-slate-200">
            <div className="block text-center text-indigo-500 hover:text-indigo-600 font-medium px-3 py-4">
              <div className="flex">
                <div className="flex mb-2">
                  <div className="relative inline-flex items-center justify-center mr-5">
                    <img
                      className="rounded-md"
                      src={require(`../../images/ranked/emblems/unranked.png`)}
                      width="50"
                      height="50"
                      alt={'Rank'}
                    />
                  </div>
                  <div className="mt-1 pr-1">
                    <div className="inline-flex w-full text-slate-800 hover:text-slate-900">
                      <h2 className="leading-snug justify-center font-semibold">
                        Unranked
                      </h2>
                    </div>
                    <div className="flex flex-col items-start">
                      <span>N/A</span>
                      <span className="font-medium text-slate-400">N/A</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
