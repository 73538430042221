import React from 'react';

import { NavLink, useLocation } from 'react-router-dom';
import { IconType } from 'react-icons';

interface IProps {
  icon: IconType;
  sidebarExpanded: boolean;
  path: string;
  title: string;
  badgeCount?: number;
}

const Link: React.FC<IProps> = ({
  icon,
  sidebarExpanded,
  path,
  title,
  badgeCount,
}) => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <li
      className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 border-l-4 ${
        pathname.includes(path)
          ? 'duration-700 border-indigo-400 bg-slate-900'
          : 'border-transparent'
      }`}
    >
      <NavLink
        end
        to={path}
        className={`bloc truncate transition duration-150 ${
          pathname.includes(path) && 'text-indigo-400'
        } hover:text-indigo-400 text-slate-200`}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            {React.createElement(icon, {
              className: `duration-200 ${
                !sidebarExpanded && 'flex-shrink-0 -ml-1'
              }`,
              size: 26,
            })}
            <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
              {title}
            </span>
          </div>
          {badgeCount ? (
            <div
              className={`flex flex-shrink-0 ml-2 ${
                !pathname.includes(path) && 'animate-bounce'
              }`}
            >
              <span className="inline-flex items-center justify-center h-5 text-xs font-medium text-white bg-indigo-500 px-2 rounded">
                {badgeCount}
              </span>
            </div>
          ) : (
            <></>
          )}
        </div>
      </NavLink>
    </li>
  );
};

export default Link;
